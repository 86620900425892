<template>
  <button class="button">
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: 'Button',
};
</script>
<style lang="scss" scoped>
  .button {
    background: $brandPrimary;
    color: white;
    font-size: 1rem;
    padding: .5rem 2rem;
    border: none;
    border-radius: 5px;
    height: 2.5rem;
    transition: all .25s;

    &:hover {
      background: darken($brandPrimary, 10);
    }
  }
</style>
